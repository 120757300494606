import React, { useState, useEffect, useMemo } from "react";
import { Routes, Route, Navigate, useLocation } from "react-router-dom";
import { ThemeProvider } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
import Icon from "@mui/material/Icon";
import MDBox from "./components/MDBox";
import Box from "@mui/material/Box";
import Sidenav from "./examples/Sidenav";
import Configurator from "./examples/Configurator";
import theme from "./assets/theme";
import themeRTL from "./assets/theme/theme-rtl";
import themeDark from "./assets/theme-dark";
import themeDarkRTL from "./assets/theme-dark/theme-rtl";
import rtlPlugin from "stylis-plugin-rtl";
import { CacheProvider } from "@emotion/react";
import createCache from "@emotion/cache";
import routes from "./routes";
import { useMaterialUIController, setMiniSidenav, setOpenConfigurator } from "./context";
import brandWhite from "./assets/images/logo-ct.png";
import brandDark from "./assets/images/logo-ct-dark.png";

interface Route {
  type?: string;
  name?: string;
  key?: string;
  icon?: JSX.Element;
  route: string;
  component: JSX.Element;
  onClick?: () => void;
}

const App: React.FC = () => {
  const [controller, dispatch] = useMaterialUIController();
  const {
    miniSidenav,
    direction,
    layout,
    openConfigurator,
    sidenavColor,
    transparentSidenav,
    whiteSidenav,
    darkMode,
  } = controller;
  const { superAdminRoutes, notLoggedInRoutes } = useMemo(() => routes(), []);
  const [onMouseEnter, setOnMouseEnter] = useState<boolean>(false);
  const [rtlCache, setRtlCache] = useState<any>(null);
  const { pathname } = useLocation();

  useMemo(() => {
    const cacheRtl = createCache({
      key: "rtl",
      stylisPlugins: [rtlPlugin],
    });

    setRtlCache(cacheRtl);
  }, []);

  const handleOnMouseEnter = () => {
    if (miniSidenav && !onMouseEnter) {
      setMiniSidenav(dispatch, false);
      setOnMouseEnter(true);
    }
  };

  const handleOnMouseLeave = () => {
    if (onMouseEnter) {
      setMiniSidenav(dispatch, true);
      setOnMouseEnter(false);
    }
  };
  const handleConfiguratorOpen = () => setOpenConfigurator(dispatch, !openConfigurator);

  useEffect(() => {
    document.body.setAttribute("dir", direction);
  }, [direction]);

  useEffect(() => {
    document.documentElement.scrollTop = 0;
    if (document.scrollingElement) {
      document.scrollingElement.scrollTop = 0;
    }
  }, [pathname]);

  const currentRole = localStorage.getItem("role");
  const currentRoutes =
    currentRole === "superadmin"
      ? superAdminRoutes
      : notLoggedInRoutes;

  return direction === "rtl" ? (
    <CacheProvider value={rtlCache}>
      <ThemeProvider theme={darkMode ? themeDarkRTL : themeRTL}>
        <CssBaseline />
        {layout === "dashboard" && (
          <>
            <Sidenav
              color={sidenavColor}
              brand={(transparentSidenav && !darkMode) || whiteSidenav ? brandDark : brandWhite}
              brandName="Material Dashboard 2"
              routes={currentRoutes} // Use currentRoutes here
              onMouseEnter={handleOnMouseEnter}
              onMouseLeave={handleOnMouseLeave}
            />
            <Configurator />
            <Box
              display="flex"
              justifyContent="center"
              alignItems="center"
              width="3.25rem"
              height="3.25rem"
              position="fixed"
              right="2rem"
              bottom="2rem"
              zIndex={99}
              sx={{ cursor: "pointer" }}
              onClick={handleConfiguratorOpen}
            >
              <Icon fontSize="small" color="inherit">
                settings
              </Icon>
            </Box>
          </>
        )}
        {layout === "vr" && <Configurator />}
        <Routes>
          {currentRoutes.map((route) => ( 
            // Iterate over currentRoutes directly
            <Route
              key={route.key}
              path={route.route}
              element={route.component}
            />
          ))}
          {localStorage.getItem("loggedIn") == "true" ? (
            <Route path="*" element={<Navigate to="/dashboard" />} />
          ) : (
            <Route path="*" element={<Navigate to="/sign-in" />} />
          )}
        </Routes>
      </ThemeProvider>
    </CacheProvider>
  ) : (
    <ThemeProvider theme={darkMode ? themeDark : theme}>
      <CssBaseline />
      {layout === "dashboard" && (
        <>
          <Sidenav
            color={sidenavColor}
            brand={(transparentSidenav && !darkMode) || whiteSidenav ? brandDark : brandWhite}
            brandName="Material Dashboard 2"
            routes={currentRoutes} // Use currentRoutes here
            onMouseEnter={handleOnMouseEnter}
            onMouseLeave={handleOnMouseLeave}
          />
          <Configurator />
          <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            width="3.25rem"
            height="3.25rem"
            position="fixed"
            right="2rem"
            bottom="2rem"
            zIndex={99}
            sx={{ cursor: "pointer" }}
            onClick={handleConfiguratorOpen}
          >
            <Icon fontSize="small" color="inherit">
              settings
            </Icon>
          </Box>
        </>
      )}
      {layout === "vr" && <Configurator />}
      <Routes>
        {currentRoutes.map((route) => (
          <Route
            key={route.key}
            path={route.route}
            element={route.component}
          />
        ))}
        {localStorage.getItem("loggedIn") == "true" ? (
          <Route path="*" element={<Navigate to="/dashboard" />} />
        ) : (
          <Route path="*" element={<Navigate to="/sign-in" />} />
        )}
      </Routes>
    </ThemeProvider>
  );
}

export default App;
